import React, { useState, useMemo } from "react";
import { Dimensions, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { useTailwind } from "tailwind-rn";

const AverageCartChart = (props) => {
  const tailwind = useTailwind();
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0, visible: false, value: 0, date: '' });
  const chartPrimaryColor = "#06f62d"

  const chartConfig = {
    backgroundColor: "#ffffff",
    backgroundGradientFrom: "#ffffff",
    backgroundGradientTo: "#ffffff",
    decimalPlaces: 2,
    color: (opacity = 1) => `rgba(0,0,0 , ${opacity})`,
    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    fillShadowGradient: 'transparent',
    strokeWidth: 3,
    propsForDots: {
      r: '3',
      strokeWidth: '2',
      stroke: `${chartPrimaryColor}`,
      fill: `${chartPrimaryColor}`,
    },
  };

  const labels = props?.analyticsData?.averageCartVsDate
    ?.sort((a, b) =>
      new Date(a.date.split("-").reverse().join("-")) -
      new Date(b.date.split("-").reverse().join("-"))
    )
    ?.map((cart) => cart?.date);

  const data = {
    labels: labels.map((label, idx) => {
      const labelRadix = Math.max(Math.floor(labels.length / 5), 1);
      return idx % labelRadix === 0 ? label : '';
    }),
    datasets: [
      {
        label: "Average Cart",
        data: props?.analyticsData?.averageCartVsDate
          ?.sort((a, b) =>
            new Date(a.date.split("-").reverse().join("-")) -
            new Date(b.date.split("-").reverse().join("-"))
          )
          ?.map((cart) => cart?.cartValues),
        color: (opacity = 1) => `rgba(6,246,45 , ${opacity})`,
        borderColor: "rgb(0, 255, 0)",
        backgroundColor: "rgba(0, 255, 0, 0.5)",
      },
    ],
  };

  const chartWidth = useMemo(() => {
    if (Dimensions.get('window').width >= 870) {
      return ((Dimensions.get("window").width - 150) / 3) - 50;
    } else if (Dimensions.get('window').width < 870 && Dimensions.get('window').width >= 768) {
      return Dimensions.get("window").width - 73;
    }
    return (Dimensions.get("window").width - 45);
  }, [Dimensions.get("window").width]);

  return (
    <View
      style={[
        tailwind(
          "bg-white p-2 text-left text-base font-bold w-full rounded-[7px] pt-4"
        ),
      ]}
    >
      <Text style={tailwind("mb-2.5 font-poppins-bold text-base")}>
        Average Cart Values -{" "}
        <Text style={tailwind("text-colorSkyblue_100 font-poppins-bold")}>
          {props?.currentCurrency}
          {props?.analyticsData?.averageCartValue?.toFixed(2)}
        </Text>
      </Text>
      <View style={tailwind("flex")}>
        <LineChart
          data={data}
          width={chartWidth}
          height={340}
          xLabelsOffset={-10}
          verticalLabelRotation={60}
          chartConfig={chartConfig}
          style={{ marginRight: -28, marginLeft: -8 }}
          onDataPointClick={(data) => {
            const index = data.index;
            const date = labels[index]; // Get the corresponding date (x-axis label)
            
            let isSamePoint = (tooltipPos.x === data.x && tooltipPos.y === data.y);
            if (isSamePoint) {
              // If clicking the same point, toggle visibility
              setTooltipPos((prevState) => ({
                ...prevState,
                visible: !prevState.visible,
              }));
            } else {
              // Show tooltip with the value and date on point click
              setTooltipPos({
                x: data.x,
                y: data.y,
                value: data.value,
                date: date,  // Add the corresponding date
                visible: true,
              });
            }
          }}
        />
        {tooltipPos.visible && (
          <View
            style={{
              position: 'absolute',
              left: tooltipPos.x - 20,
              top: tooltipPos.y + 10,
              backgroundColor: 'rgba(0,0,0,0.7)',
              padding: 5,
              borderRadius: 5,
              minWidth:90
            }}
          > 
          <Text style={tailwind('font-poppins-semiBold text-[12px] text-white')}>{tooltipPos.date}</Text>
          <View style={tailwind('w-full flex flex-row items-center mt-1')}>
            <View style={tailwind(`w-[10px] h-[10px] bg-[${chartPrimaryColor}] mr-1`)}>

              </View>
          <Text style={{ color: '#fff', fontSize: 12 }}>
               Average Cart: {parseFloat(tooltipPos?.value).toFixed(2)}
            </Text>
            </View>

           
          </View>
        )}
      </View>
    </View>
  );
};

export default AverageCartChart;
