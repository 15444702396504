import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
  Button,
  ScrollView,
} from "react-native";
import {
  addProduct,
  askuploadtype,
  editProduct,
  editStore,
  editsite,
  editstore,
  getSiteDetails,
  getproductlist,
  getstorelist,
  updateimagetoCdn,
} from "../api";
import * as yup from "yup";
import { useFormik } from "formik";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";
import { Icon, Menu, Modal, Portal, TextInput } from "react-native-paper";
import { Defs, FeColorMatrix, Filter, Path, Svg } from "react-native-svg";
import * as ImagePicker from "expo-image-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setStoreListData } from "../utils/redux/slice/StoreSlice";
import { useDispatch } from "react-redux";
import {
  formatCurrency,
  getSupportedCurrencies,
} from "react-native-format-currency";
import ColorPicker, {
  Panel1,
  Swatches,
  Preview,
  OpacitySlider,
  HueSlider,
} from "reanimated-color-picker";


const SiteSettingsForm = ({ hideModal, selectedStore }) => {
  const tailwind = useTailwind();

  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [file1, setFile1] = useState(null);
  const [imageAsset1, setImageAsset1] = useState(null);
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [showCurrencyMenu, setShowCurrencyMenu] = useState(false);
  const [siteDetails, setSiteDetails] = useState();
  const [visible, setVisible] = React.useState(false);
  const [langMenuVisible, setLangMenuVisible] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const menuIcon = require("../assets/menu-down.svg");

  const menuStyles = {
    zIndex: 100,
    width: 150,
    maxHeight: 250,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "32%" : "57%",
  };
  const menuStyles2 = {
    zIndex: 100,
    width: 110,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "23%" : "30%",
    right: Dimensions.get("window").width < 840 ? "70%" : "11%",
  };

  const validationSchema = yup.object().shape({
    siteName: yup.string().required("Store Name is required."),
    primaryColor: yup.string(),
    appLogo: yup.string(),
    siteFavicon: yup.string(),
    siteLanguages: yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      siteName: siteDetails?.siteName ? siteDetails?.siteName : "",
      primaryColor: siteDetails?.primaryColor ? siteDetails?.primaryColor : "",

      siteFavicon: siteDetails?.favicon ? siteDetails.favicon : "",

      appLogo: siteDetails?.appLogo ? siteDetails.appLogo : "",

      siteLanguages: siteDetails?.languages ? siteDetails?.languages : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values, 'values');

      AsyncStorage.getItem("token").then((token) => {
        if (
          (token === null || token === "null" || token === undefined || !token)
        ) {
          if (false) {
            let bodyFormData = new FormData();

            askuploadtype(file.type, token)
              .then((result) => {
                bodyFormData.append("file", file);
                // console.log(result.data.data.uploadURL, "ASDK RESULT");
                updateimagetoCdn(
                  result.data.data.uploadURL,
                  bodyFormData,
                  token
                )
                  .then((res) => {
                    // console.log(res.data.result);
                    values.productImage = res.data.result.variants[0];
                    editsite(
                      siteDetails.siteName,
                      values,
                      token,
                      selectedLanguage
                    )
                      .then((res) => {
                        setLoader(false);
                        setOpenSiteEditModal(false);
                        toast.success("Product updated successfully!");

                        resetForm({ values: "" });
                        getstorelist(selectedStore.storeId)
                          .then((res) => {
                            // console.log('Product list response',res)
                            dispatch(setStoreListData(res.data.message));
                            setImageAsset(null);

                            setFaviconAsset(null);
                          })
                          .catch((err) => {
                            console.log("Error in Product list", err);
                          });
                      })
                      .catch((err) => {
                        setLoader(false);
                        setOpenSiteEditModal(false);
                        console.error(err);
                        toast.error(err.message);
                        // toast.error("Something went wrong while editing location!");
                      });
                    setLoader(true);
                  })
                  .catch((err) => {
                    console.log("Error in Image to CDN Upload", err);
                  });
              })
              .catch((err) => {
                console.log("Error in Ask Image Upload", err);
              });
            setLoader(true);
          } else {
            editsite(siteDetails.siteName, values, token)
              .then((res) => {
                setLoader(false);
                closeModal();
                // toast.success("Store updated successfully!");
                // getstorelist(selectedStore.storeId)
                //   .then((res) => {

                //     // console.log('Product list response',res)
                //     setstorelist(res.data.message);

                //   })
                //   .catch((err) => {
                //     console.log("Error in Product list", err);
                //   });
                resetForm({ values: "" });
              })
              .catch((err) => {
                setLoader(false);
                closeModal();
                console.error(err);
                // toast.error(err.message);
                // toast.error("Something went wrong while editing location!");
              });
          }

          //   setLoader(true);
        }
      });
    },
  });

  useEffect(() => {
    console.log('values set')
    formik.setValues({
      siteName: siteDetails?.siteName ? siteDetails?.siteName : "",
      primaryColor: siteDetails?.primaryColor ? siteDetails?.primaryColor : "",

      // siteFavicon: siteDetails?.favicon ? siteDetails.favicon.data : "",
      siteFavicon: `${process.env.REACT_APP_ORDERAT}/site/${process.env.REACT_APP_SITE_NAME}/getFavicon` ? `${process.env.REACT_APP_ORDERAT}/site/${process.env.REACT_APP_SITE_NAME}/getFavicon` : "",

      appLogo: siteDetails?.appLogo ? siteDetails.appLogo : "",

      siteLanguages: siteDetails?.languages ? siteDetails?.languages : [],
    });

    // formik.setFieldValue(
    //   "appLogo",
    //   selectedStore?.image ? selectedStore.image : ""
    // );
  }, [siteDetails]);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
        setSelectedLanguage(res.data.message.languages[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    if (imageAsset) {
      return;
    }

    if (Platform.OS === "web") {
      // For web, this will open the file input dialog.
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    } else {
      // For mobile, use ImagePicker to select from camera or gallery
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    }
  };

  const removeImage = () => {
    console.log('image removed')
    // if (formik.values.productImage !== "") {
      formik.setFieldValue("appLogo", "");
    // }
    setImageAsset(null); // Clear the image from state
    setFile(null); // Clear the image from state
  };
  const pickImage1 = async () => {
    if (imageAsset1) {
      return;
    }

    if (Platform.OS === "web") {
      // For web, this will open the file input dialog.
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset1(() => result.assets[0].uri);
        setFile1(() => result.assets[0]);
      }
    } else {
      // For mobile, use ImagePicker to select from camera or gallery
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset1(() => result.assets[0].uri);
        setFile1(() => result.assets[0]);
      }
    }
  };

  const removeImage1 = () => {
    if (formik.values.siteFavicon !== "") {
      formik.setFieldValue("siteFavicon", "");
    }
    setImageAsset1(null); // Clear the image from state
    setFile1(null); // Clear the image from state
  };

  const closeModal = () => {
    formik.setFieldValue("siteName", "");
    formik.setFieldValue("primaryColor", "");
    formik.setFieldValue("siteFavicon", "");
    formik.setFieldValue("appLogo", "");
    formik.setFieldValue("siteLanguages", []);
    hideModal();
  };

  const onSelectColor = ({ hex }) => {
    // do something with the selected color.
    console.log(hex);
  };


  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "100px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <View style={[tailwind("h-full")]}>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          Settings
        </Text>
        <Pressable onPress={() => closeModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
            style={tailwind("w-[24px] h-[24px]")}
            contentFit="cover"
            source={require("../assets/-icon-close.png")}
          />
        </Pressable>
      </View>

      <ScrollView
        contentContainerStyle={tailwind("py-2")}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* store name */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Site Name <Text style={tailwind("text-red-500")}>*</Text>
          </Text>
          <View style={tailwind("flex flex-row items-center relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/userName-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 flex-1 bg-white text-gray-600 h-[38px] font-poppins-regular"
              )}
              error={formik.errors.siteName ? true : false}
              textColor="#4b5563"
              placeholder="Site Name"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("siteName")}
              name="siteName"
              value={formik.values.siteName}
            />
          </View>
        </View>

        {/* Primary color */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Primary Color
          </Text>
          <View style={tailwind("flex flex-row items-center  relative")}>
            {/* <View
              style={tailwind(
                "flex-1 h-[30px] z-[100]"
              )}
            >
              <Button title="Color Picker" onPress={() => setShowModal(true)} />

              <Modal visible={showModal} style={modalStyles} contentContainerStyle={containerStyle}>
                <ScrollView>
                  <ColorPicker
                    style={{ width: "70%" }}
                    value="red"
                    onComplete={onSelectColor}
                  >
                    <Preview />
                    <Panel1 />
                    <HueSlider />
                    <OpacitySlider />
                    <Swatches />
                  </ColorPicker>

                </ScrollView>
                <Button title="Ok" onPress={() => setShowModal(false)} />
              </Modal>
            </View> */}
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 w-full bg-white h-[38px] text-gray-600 font-poppins-regular"
              )}
              contentStyle={tailwind("p-0 pl-4")}
              textColor="#4b5563"
              placeholder="Store Description"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("primaryColor")}
              name="primaryColor"
              value={formik.values.primaryColor}
            />
          </View>
        </View>

        {/* App Logo Upload */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            App Logo
          </Text>
          <TouchableOpacity
            style={tailwind(
              "flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer"
            )}
            onPress={pickImage}
          >
            {!imageAsset && formik.values.appLogo === "" ? (
              <View>
                <Image
                  style={[tailwind(" w-[30px] h-[20px] mx-auto")]}
                  source={require("../assets/upload-icon.svg")}
                  alt={`image-upload`}
                  contentFit="cover"
                />
                <Text
                  style={tailwind(
                    "font-poppins-medium text-base text-gray-500"
                  )}
                >
                  Click here to upload
                </Text>
              </View>
            ) : (
              <View
                style={tailwind(
                  "relative h-full w-full p-3 flex justify-center items-center"
                )}
              >
                <Image
                  style={[tailwind("w-[80%] h-[124px]")]}
                  source={
                    imageAsset
                      ? imageAsset
                      : formik.values.appLogo !== ""
                      ? formik.values.appLogo
                      : imageAsset
                  }
                  alt={`uploadedImage`}
                  contentFit="contain"
                />
                <Pressable
                  onPress={removeImage}
                  style={tailwind(
                    "absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                  )}
                >
                  <Image
                    style={[tailwind(" w-[20px] h-[20px]")]}
                    source={require("../assets/delete-icon.svg")}
                    alt={`uploadedImage`}
                    contentFit="cover"
                  />
                </Pressable>
              </View>
            )}
          </TouchableOpacity>
        </View>

        {/* Favicon Upload */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Favicon
          </Text>
          <TouchableOpacity
            style={tailwind(
              "flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer"
            )}
            onPress={pickImage1}
          >
            {!imageAsset1 && formik.values.siteFavicon === "" ? (
              <View>
                <Image
                  style={[tailwind(" w-[30px] h-[20px] mx-auto")]}
                  source={require("../assets/upload-icon.svg")}
                  alt={`image-upload`}
                  contentFit="cover"
                />
                <Text
                  style={tailwind(
                    "font-poppins-medium text-base text-gray-500"
                  )}
                >
                  Click here to upload
                </Text>
              </View>
            ) : (
              <View
                style={tailwind(
                  "relative h-full w-full p-3 flex justify-center items-center"
                )}
              >
                <Image
                  style={[tailwind("w-[80%] h-[124px]")]}
                  source={
                    imageAsset1
                      ? imageAsset1
                      : formik.values.siteFavicon !== ""
                      ? formik.values.siteFavicon
                      : imageAsset1
                  }
                  // source={
                  //   imageAsset1
                  //     ? imageAsset1
                  //     : `${process.env.REACT_APP_ORDERAT}/site/${process.env.REACT_APP_SITE_NAME}/getFavicon`
                  // }
                  alt={`uploadedImage`}
                  contentFit="contain"
                />
                <Pressable
                  onPress={removeImage1}
                  style={tailwind(
                    "absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                  )}
                >
                  <Image
                    style={[tailwind(" w-[20px] h-[20px]")]}
                    source={require("../assets/delete-icon.svg")}
                    alt={`uploadedImage`}
                    contentFit="cover"
                  />
                </Pressable>
              </View>
            )}
          </TouchableOpacity>
        </View>

        <Pressable
          style={tailwind(
            "flex items-center justify-center px-8 py-2 mx-auto my-4 rounded-lg w-max bg-colorSkyblue_100 mx-auto"
          )}
          onPress={() => {
            console.log('button pressed');
            formik.handleSubmit();
          }}
        >
          <Text
            style={tailwind(
              "font-poppins-semiBold text-center text-white uppercase "
            )}
          >
            Update
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

export default SiteSettingsForm;
