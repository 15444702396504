import { createSlice } from "@reduxjs/toolkit"

const ModalSlice = createSlice({
  name: "modalSlice",
  initialState: {
    modalVisible: false,
  },
  reducers: {
    setModalVisible: (state, action) => {
      state.modalVisible = action.payload
    },
  }
})

export const {setModalVisible} = ModalSlice.actions;
export default ModalSlice.reducer;