import { Dimensions, Text, View } from "react-native"
import Layout from "./Layout";
import StoresTable from "./StoresTable";
import { getstorelist } from "../api";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useTailwind} from 'tailwind-rn'
import { useLinkTo } from "@react-navigation/native";

const Stores = () => {
    const tailwind = useTailwind();
    const linkTo = useLinkTo();
    const [refresh, setRefresh] = useState(false);

      const handleStoreClick = (item) => {
        linkTo(`/items/${item.storeId}`);
      }

    return (
        <Layout>
            <View
            style={[
              tailwind(
                `flex flex-col bg-white p-4 rounded-[7px] h-[92%] sm:h-[90%] md:h-full w-full ${
                  Dimensions.get("window").width < 640
                    ? "min-h-[250px]"
                    : "min-h-[calc(100%-450px)]"
                }`
              ),
            ]}
          >
                <StoresTable handleStoreClick={handleStoreClick} setRefresh={setRefresh}/>
                </View>
        </Layout>
    )
}

export default Stores;
