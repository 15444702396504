import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
  Button,
  ScrollView,
} from "react-native";
import {
  addProduct,
  askuploadtype,
  editProduct,
  editStore,
  editstore,
  getSiteDetails,
  getproductlist,
  getstorelist,
  updateimagetoCdn,
} from "../api";
import * as yup from "yup";
import { useFormik } from "formik";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";
import { Icon, Menu, Modal, Portal, TextInput } from "react-native-paper";
import { Defs, FeColorMatrix, Filter, Path, Svg } from "react-native-svg";
import * as ImagePicker from "expo-image-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setStoreListData } from "../utils/redux/slice/StoreSlice";
import { useDispatch } from "react-redux";
import {
  formatCurrency,
  getSupportedCurrencies,
} from "react-native-format-currency";

// import { REACT_APP_SITE_NAME, REACT_APP_SITE_LINK , REACT_APP_ZONE} from "@env";

const EditStoreForm = ({
    hideModal,
    storeData,
    selectedStore,
}) => {
  const tailwind = useTailwind();
  
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
    const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [showCurrencyMenu, setShowCurrencyMenu] = useState(false);
  const [siteDetails, setSiteDetails] = useState();
  const [visible, setVisible] = React.useState(false);
  const [langMenuVisible, setLangMenuVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const menuIcon = require("../assets/menu-down.svg");

  const currencyCodes = getSupportedCurrencies();

  const menuStyles = {
    zIndex: 100,
    width: 150,
    maxHeight: 250,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "32%" : "57%",
  };
  const menuStyles2 = {
    zIndex: 100,
    width: 110,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "23%" : "30%",
    right: Dimensions.get("window").width < 840 ? "70%" : "11%",
  };

  const validationSchema = yup.object().shape({
    storeName: yup.string().required("Store Name is required."),
    // spanishProductName: yup.string().required("Product Name is required."),
    //productPrice: yup.string().required("Product Price is required."),
    storeDescription: yup.string(),
    storeImage: yup.string(),
    storeOwner: yup.string(),
  });

  const formik = useFormik({
        initialValues: {
            storeName: selectedStore?.name ? selectedStore.name : "",
            storeDescription: selectedStore?.description
              ? selectedStore.description
              : "",
      
            storeOwner: selectedStore?.storeManager?.name
              ? selectedStore?.storeManager?.name
              : "",
      
            storeImage: selectedStore?.image ? selectedStore.image : "",
            
          },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      AsyncStorage.getItem("token").then((token) => {
        if (
          !(token === null || token === "null" || token === undefined || !token)
        ) {
          if (imageAsset) {
            let bodyFormData = new FormData();
            askuploadtype(file?.mimeType, token)
              .then((result) => {
                bodyFormData.append("file", file);
                updateimagetoCdn(
                  result.data.data.uploadURL,
                  bodyFormData,
                  token
                )
                  .then((res) => {
                    values.productImage = res.data.result.variants[0];
                    editStore(
                        selectedStore.storeId,
                        values,
                        token,
                        selectedLanguage
                      )
                        .then((res) => {
                          setLoader(false);
                          hideModal();
                        //   toast.success("Store updated successfully!");
                          // navigate(`/items/${selectedProduct.storeId}`);
                          resetForm({ values: "" });
                          getstorelist(token)
                            .then((res) => {
                              // console.log('Product list response',res)
                              dispatch(setStoreListData(res.data.message));
                              setImageAsset(null);
                              // formik.setValues("productImage", selectedProduct?.image ? selectedProduct.image : "")
                              // navigate(`/items/${selectedProduct.storeId}`)
                              // window.location.reload(false);
                            })
                            .catch((err) => {
                              console.log("Error in Product list", err);
                            });
                        })
                        .catch((err) => {
                          setLoader(false);
                            hideModal();
                          console.error(err);
                        //   toast.error(err.message);
                          // toast.error("Something went wrong while editing location!");
                        });

                    // setLoader(true);
                  })
                  .catch((err) => {
                    console.log("Error in Image to CDN Upload", err);
                  });
              })
              .catch((err) => {
                console.log("Error in Ask Image Upload", err);
              });
            setLoader(true);
          } else {
            editStore(values, selectedStore.storeId, token, selectedLanguage)
          .then((res) => {
            // setLoader(false);
            hideModal();
            // toast.success("Store updated successfully!");
            getstorelist(token)
              .then((res) => {
                // console.log('Product list response',res)
                dispatch(setStoreListData(res.data.message));
                // setImageAsset(null)
                // formik.setValues("productImage", selectedProduct?.image ? selectedProduct.image : "")
                // window.location.reload(false);
                // navigate(`/items/${selectedProduct.storeId}`)
              })
              .catch((err) => {
                console.log("Error in Product list", err);
              });
            resetForm({ values: "" });
          })
          .catch((err) => {
            setLoader(false);
            hideModal();
            console.error(err);
            // toast.error(err.message);
            // toast.error("Something went wrong while editing location!");
          });
          }

        //   setLoader(true);
        }
      });
    },
  });

  useEffect(() => {
    if (selectedStore && selectedLanguage) {
        formik.setValues({
            storeName: selectedStore?.name[selectedLanguage?.code]
              ? selectedStore?.name[selectedLanguage?.code]
              : "",
            storeDescription: selectedStore?.description[selectedLanguage?.code]
              ? selectedStore?.description[selectedLanguage?.code]
              : "",
    
            storeImage: selectedStore?.image ? selectedStore.image : "",
            storeOwner: selectedStore?.storeManager?.name
              ? selectedStore?.storeManager?.name
              : "",
          });
        }

        formik.setFieldValue("storeImage",
            selectedStore?.image ? selectedStore.image : "",
          );
  }, []);

  const handleCurrencyChange = (newValue) => {
    // newValue contains the selected currency code or name
    // You can perform any necessary actions here, such as updating state or making API calls
    
    // For example, you can log the selected currency to the console
    console.log("Selected currency:", newValue);
    
    // If you need to update state with the selected currency, you can do so here
    // For example:
    // setSelectedCurrency(newValue);
  };

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
        setSelectedLanguage(res.data.message.languages[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedStore && selectedLanguage) {
      formik.setFieldValue("storeName", selectedStore?.name[selectedLanguage?.code]);
      formik.setFieldValue(
        "storeDescription",
        selectedStore?.description[selectedLanguage?.code]
        ? selectedStore?.description[selectedLanguage?.code]
        : ""
      );
    }
  }, [selectedLanguage]);

  const pickImage = async () => {
    if (imageAsset) {
      return;
    }

    if (Platform.OS === "web") {
      // For web, this will open the file input dialog.
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {

        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    } else {
      // For mobile, use ImagePicker to select from camera or gallery
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    }
  };

  const removeImage = () => {
    if (formik.values.productImage !== "") {
      formik.setFieldValue("productImage", "");
    }
    setImageAsset(null); // Clear the image from state
    setFile(null); // Clear the image from state
  };

  const closeModal = () => {
    formik.setFieldValue("storeName", "");
    formik.setFieldValue("storeDescription", "");
    formik.setFieldValue("storeOwner", "");
    formik.setFieldValue("storeImage", "");
    hideModal();
  };

  return (
    <View style={[tailwind("h-full")]}>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          Edit Store
        </Text>
        <Pressable onPress={() => closeModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
            style={tailwind("w-[24px] h-[24px]")}
            contentFit="cover"
            source={require("../assets/-icon-close.png")}
          />
        </Pressable>
      </View>

      <ScrollView
        contentContainerStyle={tailwind("py-2")}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* store name */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Name <Text style={tailwind("text-red-500")}>*</Text>
          </Text>
          <View style={tailwind("flex flex-row items-center relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/userName-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 flex-1 bg-white text-gray-600 h-[38px] font-poppins-regular"
              )}
              error={formik.errors.storeName ? true : false}
              textColor="#4b5563"
              placeholder="Product Name"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("storeName")}
              name="storeName"
              value={formik.values.storeName}
            />
            {siteDetails && (
              <Menu
                visible={langMenuVisible}
                onDismiss={() => setLangMenuVisible(false)}
                contentStyle={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#00b6d3",
                }}
                style={menuStyles2}
                anchor={
                  <View
                    style={[
                      tailwind(
                        "w-full my-auto px-4 py-1.5 bg-colorSkyblue_100 rounded-md h-[38px]"
                      ),
                    ]}
                  >
                    <Pressable
                      style={tailwind("font-poppins-regular h-full")}
                      onPress={() => setLangMenuVisible(true)}
                    >
                      <Text
                        style={tailwind(
                          "font-poppins-regular border-b border-b-white text-sm text-white flex flex-row w-full justify-between items-center my-auto text-center"
                        )}
                      >
                        {selectedLanguage?.name}
                        <View
                          style={tailwind(
                            "flex flex-row items-center mb-2 sm:mb-0"
                          )}
                        >
                          <Image
                            style={tailwind(
                              `w-[13px] h-[7px] mb-[${
                                Platform.OS === "web" ? 4 : 7
                              }px]`
                            )}
                            contentFit="cover"
                            source={require("../assets/menu-down-white.svg")}
                          />
                        </View>
                      </Text>
                    </Pressable>
                  </View>
                }
              >
                {siteDetails?.languages?.map((language, index) => {
                  return (
                    <Pressable
                      key={"distribution__no_" + index}
                      onPress={() => {
                        setSelectedLanguage(language);
                        setLangMenuVisible(false);
                      }}
                      style={tailwind(
                        `flex w-full px-2 items-center justify-start rounded-md py-2`
                      )}
                    >
                      <Text style={tailwind("text-sm text-colorSkyblue_100")}>
                        {language?.name}
                      </Text>
                    </Pressable>
                  );
                })}
              </Menu>
            )}
          </View>
        </View>

        {/* store description */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Description
          </Text>
          <View style={tailwind("flex flex-row items-center  relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/desc-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 w-full bg-white text-gray-600 font-poppins-regular"
              )}
              contentStyle={tailwind("p-0 pl-4 pt-2")}
              multiline={true}
              numberOfLines={2}
              textColor="#4b5563"
              placeholder="Store Description"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("storeDescription")}
              name="storeDescription"
              value={formik.values.storeDescription}
            />
          </View>
        </View>

        {/* store owner */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Owner
          </Text>
          <View style={tailwind("flex flex-row items-center  relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/desc-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 w-full bg-white text-gray-600 font-poppins-regular"
              )}
              contentStyle={tailwind("p-0 pl-4 pt-2")}
              multiline={true}
              numberOfLines={2}
              textColor="#4b5563"
              placeholder="Store Owner"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("storeOwner")}
              name="storeOwner"
              value={formik.values.storeOwner}
            />
          </View>
        </View>

        {/* currency */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Currency
             {/* <Text style={tailwind("text-red-500")}>*</Text> */}
          </Text>
          <View
            style={tailwind(
              "border-b border-colorSkyblue_100 w-full bg-white h-[38px] font-poppins-regular py-2"
            )}
          >
        <Menu
                visible={showCurrencyMenu}
                onDismiss={() => setShowCurrencyMenu(false)}
                contentStyle={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#00b6d3",
                  // overflow: 'scroll',
                  maxHeight: 250
                }}
                style={menuStyles}
                anchor={
                  <View style={tailwind("w-full my-auto")}>
                  <Pressable
                    style={tailwind("font-poppins-regular h-full ")}
                    onPress={() => setShowCurrencyMenu(true)}
                  >
                    <Text
                      style={tailwind(
                        "font-poppins-regular text-base text-gray-600 flex flex-row w-full justify-between items-center my-auto h-full"
                      )}
                    >
                      {selectedCurrency?(selectedCurrency?.code):"Select Currency"}
                      <View
                        style={tailwind(
                          "flex flex-row items-center mb-2 sm:mb-0"
                        )}
                      >
                        <Image
                          style={tailwind(
                            `w-[15px] h-[9px] mb-[${
                              Platform.OS === "web" ? 3 : 6
                            }px]`
                          )}
                          contentFit="cover"
                          source={menuIcon}
                        />
                      </View>
                    </Text>
                  </Pressable>
                </View>
                }
              >
                <ScrollView style={tailwind('h-full')} contentContainerStyle={tailwind('')} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} scrollEnabled={true}>
                  {currencyCodes?.map((item) => {
                      const [valueFormattedWithSymbol, valueFormattedWithoutSymbol, symbol] =
                      formatCurrency({ amount: Number(50), code: item.code });
                      // console.log(valueFormattedWithSymbol, valueFormattedWithoutSymbol, 'currency');
                      return (
                      <Pressable
                        key={"distribution__no_" + item.code}
                        onPress={() => {
                          setSelectedCurrency(() => ({symbol:symbol,code:item.code, name: item.name}));
                          setShowCurrencyMenu(false);
                        }}
                        style={tailwind(
                          `flex w-full px-2 items-center justify-start rounded-md py-2`
                        )}
                      >
                        <Text style={tailwind("text-sm text-colorSkyblue_100")}>
                          {item.code}
                        </Text>
                      </Pressable>
                    );
                  })}
                </ScrollView>
              </Menu>
              </View>
              </View>


        {/* Image Upload */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Image
          </Text>
          <TouchableOpacity
            style={tailwind(
              "flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer"
            )}
            onPress={pickImage}
          >
            {!imageAsset && formik.values.productImage === "" ? (
              <View>
                <Image
                  style={[tailwind(" w-[30px] h-[20px] mx-auto")]}
                  source={require("../assets/upload-icon.svg")}
                  alt={`image-upload`}
                  contentFit="cover"
                />
                <Text
                  style={tailwind(
                    "font-poppins-medium text-base text-gray-500"
                  )}
                >
                  Click here to upload
                </Text>
              </View>
            ) : (
              <View
                style={tailwind(
                  "relative h-full p-3 flex justify-center items-center"
                )}
              >
                <Image
                  style={[tailwind("w-[124px] h-[124px]")]}
                  source={
                    imageAsset
                      ? imageAsset
                      : formik.values.storeImage !== ""
                      ? formik.values.storeImage
                      : imageAsset
                  }
                  alt={`uploadedImage`}
                  contentFit="cover"
                />
                <Pressable
                  onPress={removeImage}
                  style={tailwind(
                    "absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                  )}
                >
                  <Image
                    style={[tailwind(" w-[20px] h-[20px]")]}
                    source={require("../assets/delete-icon.svg")}
                    alt={`uploadedImage`}
                    contentFit="cover"
                  />
                </Pressable>
              </View>
            )}
          </TouchableOpacity>
        </View>

        <Pressable
          style={tailwind(
            "flex items-center justify-center px-8 py-2 mx-auto my-4 rounded-lg w-max bg-colorSkyblue_100 mx-auto"
          )}
          onPress={() => {
            formik.handleSubmit();
          }}
        >
          <Text
            style={tailwind(
              "font-poppins-semiBold text-center text-white uppercase "
            )}
          >
            Update
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

export default EditStoreForm;
