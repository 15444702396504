// WebSocketConnection.js
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalChild, setModalVisible } from "../redux/slice/ModalSlice";
import { io } from "socket.io-client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getorderlist } from "../../api";
import { Audio } from "expo-av";
import { setNewOrdersCount } from "../redux/slice/OrdersSlice";

export async function playSound(setStoredSound, dispatch) {
    const { sound } = await Audio.Sound.createAsync(
      require("../../sounds/alert.wav")
    );
    // dispatch(setStoredSound(sound));
    setStoredSound(() => sound);
    // console.log("Playing Sound");
    await sound.playAsync();
  }

 export const stopSound = (storedSound) => {
    storedSound ? storedSound.unloadAsync() : undefined;
    // console.log("sound stopped");
  };

const useWebSocket = (storedSound, setStoredSound) => {
  const store = useSelector((state) => state?.store?.selectedStore.storeId);
// const storedSound= useSelector((state) => state.modal.storedSound);
  const socket = useMemo(() => io(process.env.SOCKET_URL),[]);
  const dispatch = useDispatch();

  const fetchOrders = (checkNewOrders) => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getorderlist(10, 1, token, selectedStore, dispatch)?.then((res) => {
          checkForNewOrders(res.data.order, checkNewOrders);
        });
      }
    });
  };

  const checkForNewOrders = (ordersData, checkNewOrders) => {
    const latestNewOrders = ordersData.filter(
      (order) => order?.statusCode <= 2
    );
    if (latestNewOrders.length > 0 && checkNewOrders) {
      playSound(setStoredSound, dispatch);
      dispatch(setModalVisible(true));
    }

    dispatch(setNewOrdersCount(latestNewOrders.length));
  };

//   useEffect(() => {
//     // const socket = io(process.env.SOCKET_URL);

//     AsyncStorage.getItem("userId").then((userId) => {
//       socket.auth = { userId: userId };
//       socket.connect(process.env.SOCKET_URL);
//     });

//     if (store) {
//       socket.emit("joinRoom", { roomId: store });
//     }

//     socket.on("orderReceived", (data) => {
//       // console.log(data, 'data socket')
//       fetchOrders(1);
//     });

//     socket.onerror = (error) => {
//       console.error("WebSocket error: ", error);
//     };

//     return () => {
//       socket.close();
//     };
//   }, [dispatch, store]);


  useEffect(() => {
    AsyncStorage.getItem("userId").then((userId) => {
      socket.auth = { userId: userId };
      socket.connect(process.env.SOCKET_URL);
    });
  }, [socket]);

    useEffect(() => {
    if(store){
      socket.emit("joinRoom", { roomId: store });
    }
    return () => socket.off("joinRoom"); // add this line to your code
  }, [dispatch, store]);

  useEffect(() => {
    socket.on("orderReceived", (data) => {
      fetchOrders(1);
    });

    return () => socket.off("orderReceived"); // add this line to your code
  }, [socket]);
};

export default useWebSocket;
