import { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { Dimensions, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { lightBlue100 } from "react-native-paper/lib/typescript/styles/themes/v2/colors";
import { useTailwind } from "tailwind-rn";

const screenWidth = Dimensions.get("window").width;
const TotalOrdersChart = (props) => {

    const tailwind = useTailwind();
    const chartPrimaryColor = "#ff6384";
    const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0, visible: false, value: 0, date: '' });


    const chartConfig = {
 
      backgroundColor: "#ffffff", // Solid background color
      backgroundGradientFrom: "#ffffff", // Start of gradient (same color for solid)
      backgroundGradientTo: "#ffffff", // End of gradient (same color for solid)
      decimalPlaces: 2, // number of decimal places to show
      color: (opacity = 1) => `rgba(0,0,0 , ${opacity})`,
      labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // X and Y axis label color
      backgroundGradientFromOpacity: 0, // Remove gradient background
      backgroundGradientToOpacity: 0,   // Remove gradient background
      fillShadowGradient: 'transparent',
      fillShadowGradientFrom: 'transparent',
      fillShadowGradientTo: 'transparent',
      fillShadowGradientOpacity: 0, // Ensure no fill color
      strokeWidth: 3, // optional, default 3
      barPercentage: 5.5,
      propsForDots: {
        r: '3',
        strokeWidth: '2',
        stroke: `${chartPrimaryColor}`, // Points border color
        fill: `${chartPrimaryColor}`,   // Points fill color
      },
    };
  

  const labels = props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map((order) => order?.date)

  const data = {
    labels: labels.map((label, idx) =>
      {

        const labelRadix = Math.max(Math.floor(labels.length / 5), 1)
        return idx % labelRadix === 0 ? label : ''
      },
  ),
    datasets: [
      {
        label: "Total Orders",
        data: props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map(
          (order) => order?.orders
        ),
        color: (opacity = 1) => `rgba(255,99,132 , ${opacity})`,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const chartWidth = useMemo(() => {
    if(Dimensions.get('window').width>=870){
      return ((Dimensions.get("window").width - 150)/3)-50;

    }
    else if(Dimensions.get('window').width<870 && Dimensions.get('window').width>=768){
      return Dimensions.get("window").width - 73;
    }

    return  Dimensions.get("window").width - 45;
  }, [Dimensions.get("window").width]);

  return (
    <View style={[tailwind("bg-white p-2 text-left text-base font-bold rounded-[7px] pt-4 w-full")]}>
      <Text style={tailwind("mb-2.5 font-poppins-bold text-base")}>
        {" "}
        Total Orders -{" "}
        <Text style={tailwind("text-colorSkyblue_100 font-poppins-bold")}>
        {props?.analyticsData?.totalOrders}
        </Text>
      </Text>
      <View style={tailwind("flex rounded-[7px]")}>
        <LineChart data={data} width={chartWidth} xLabelsOffset={-10} height={340} verticalLabelRotation={60}  chartConfig={chartConfig}    style={{
            marginRight: -28,
            marginLeft: -8,
          }}
          onDataPointClick={(data) => {
            const index = data.index;
            const date = labels[index]; // Get the corresponding date (x-axis label)
            
            let isSamePoint = (tooltipPos.x === data.x && tooltipPos.y === data.y);
            if (isSamePoint) {
              // If clicking the same point, toggle visibility
              setTooltipPos((prevState) => ({
                ...prevState,
                visible: !prevState.visible,
              }));
            } else {
              // Show tooltip with the value and date on point click
              setTooltipPos({
                x: data.x,
                y: data.y,
                value: data.value,
                date: date,  // Add the corresponding date
                visible: true,
              });
            }
          }}
          />
          {tooltipPos.visible && (
          <View
            style={[tailwind('absolute p-[5px] rounded-[5px] min-w-[90px]'),{
              left: tooltipPos.x - 20,
              top: tooltipPos.y + 10,
              backgroundColor: 'rgba(0,0,0,0.7)',
                          }]}
          > 
          <Text style={tailwind('font-poppins-semiBold text-[12px] text-white')}>{tooltipPos.date}</Text>
          <View style={tailwind('w-full flex flex-row items-center mt-1')}>
            <View style={tailwind(`w-[10px] h-[10px] bg-[${chartPrimaryColor}] mr-1`)}>

              </View>
          <Text style={{ color: '#fff', fontSize: 12 }}>
               Total Orders: {parseFloat(tooltipPos?.value).toFixed(2)}
            </Text>
            </View>

           
          </View>
        )}
      </View>
    </View>
  );
};


export default TotalOrdersChart;
