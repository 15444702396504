import { Image } from "expo-image";
import React, { useState } from "react";
import {
  Dimensions,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { DataTable, Text, Tooltip } from "react-native-paper";
import { useTailwind } from "tailwind-rn";

const Table = ({
  filteredOrders,
  currentStore,
  currentStoreType,
  currentStoreTimezone,
  paginationLength,
  showCount,
  size,
  page,
  handleChangetwo,
}) => {
  const tailwind = useTailwind();

  //variables for table width according to the width of the window to make it responsive across all devices
  const factor = Dimensions.get("window").width >= 870 ? 165 : 64;
  const tableMaxWidth =
    Dimensions.get("window").width - factor >= 650
      ? Dimensions.get("window").width - factor
      : 650;

  //pagination page no boxes
  const getPagesBoxes = () => {
    const pageBoxes = [];
    for (let i = 0; i < showCount; i++) {
      const background = i + 1 === page ? "bg-colorSkyblue_100" : "";
      const textColor =
        i + 1 === page ? "text-white " : "text-colorSkyblue_100";
      const box = (
        <Pressable
          onPress={() => handleChangetwo(i + 1)}
          style={tailwind(
            `w-[34px] border border-colorSkyblue_100 flex justify-center items-center px-2 py-1 ml-3 rounded-lg ${background}`
          )}
        >
          <Text
            style={tailwind(
              `text-colorSkyblue_100 font-poppins-semiBold ${textColor}`
            )}
          >
            {i + 1}
          </Text>
        </Pressable>
      );

      pageBoxes.push(box);
    }

    return pageBoxes;
  };

  return (
    <View style={tailwind("w-full")}>
      {filteredOrders?.length > 0 ? (
        <>
          {/* Table */}

          <ScrollView
            horizontal
            style={tailwind("")}
            contentContainerStyle={[tailwind(``), { width: tableMaxWidth }]}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <DataTable style={tailwind("w-full")}>
              {/* table header */}
              <DataTable.Header style={tailwind("bg-[#E9EFF6] border-b-0")}>
                <View style={tailwind("w-[100px] flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    OrderId
                  </Text>
                </View>
                <View style={tailwind("w-[110px] flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Status
                  </Text>
                </View>
                <View style={tailwind("w-[150px] flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                    Delivery Gate
                  </Text>
                </View>
                {currentStoreType === "self" && (
                  <View
                    style={tailwind(
                      "flex-1 min-w-[230px]  flex  justify-center p-4"
                    )}
                  >
                    <Text
                      style={tailwind(
                        "text-colorSkyblue_100 text-base font-poppins-semiBold"
                      )}
                    >
                      {" "}
                      Order Info
                    </Text>
                  </View>
                )}
                <View style={tailwind("w-[120px] flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                    Time Stamp
                  </Text>
                </View>
              </DataTable.Header>

              {/* rendering table rows from filteredorders */}
              {filteredOrders?.map((item, index) => {
                if (item.storeId === currentStore) {
                  return (
                    <DataTable.Row key={index} style={tailwind("border-b-0")}>
                      <View
                        style={tailwind("w-[100px] flex  p-4 justify-center")}
                      >
                        <Text
                          style={tailwind(
                            "text-black text-sm font-poppins-regular"
                          )}
                        >
                          {item.orderId}
                        </Text>
                      </View>
                      <View
                        style={tailwind("w-[110px] flex  p-4 justify-center")}
                      >
                        <Tooltip
                          // visible={item.status === 'order cancelled'}
                          title={` ${item.cancellationReason.text}
                              ${
                                item.cancellationReason.unavailableItems?.length
                                  ? `\nunavailable items: ${item.cancellationReason.unavailableItems}`
                                  : ""
                              }`}
                        >
                          <Text
                            style={[
                              tailwind("text-black text-sm font-poppins-bold"),
                              getStatusclass(item.status),
                            ]}
                          >
                            {item.status}
                          </Text>
                        </Tooltip>
                      </View>

                      <View
                        style={tailwind("w-[150px] flex  p-4 justify-center")}
                      >
                        <Text
                          style={tailwind(
                            "text-black text-sm font-poppins-regular"
                          )}
                        >
                          {item.drop.locationName &&
                            Object.values(item.drop.locationName)[0]}
                        </Text>
                      </View>

                      {currentStoreType === "self" && (
                        <View
                          style={tailwind(
                            "flex-1 min-w-[230px] flex  p-4 justify-center"
                          )}
                        >
                          <Text
                            style={tailwind(
                              "text-black flex flex-col text-sm font-poppins-regular"
                            )}
                          >
                            {item?.orderData?.map((itm, index) => {
                              return (
                                <Text
                                  key={"OrderInfo" + index}
                                  style={[
                                    tailwind(
                                      "text-black text-sm font-poppins-regular"
                                    ),
                                  ]}
                                >
                                  {itm?.name?.includes("x1")
                                    ? itm?.name?.split("x1")[0]
                                    : itm?.name}
                                  <Text
                                    style={tailwind(
                                      "text-black text-sm font-poppins-bold"
                                    )}
                                  >
                                    {" "}
                                    X{" " + itm?.quantity}
                                  </Text>
                                </Text>
                              );
                            })}
                          </Text>
                        </View>
                      )}

                      <View
                        style={tailwind("w-[120px] flex  p-4 justify-center")}
                      >
                        <Text
                          style={tailwind(
                            "text-black text-sm font-poppins-regular"
                          )}
                        >
                          {new Date(item.createdAt).toLocaleString("en-GB", {
                            timeZoneName: "short",
                            timeZone: currentStoreTimezone,
                          })}
                        </Text>
                      </View>
                    </DataTable.Row>
                  );
                } else {
                  return null;
                }
              })}
            </DataTable>
          </ScrollView>

          {/* Pagination */}
          <View style={tailwind("flex flex-row mt-4 mb-2")}>
            {paginationLength && filteredOrders.length > 0 ? (
              // Replace with appropriate pagination component for react-native-paper
              <View style={tailwind("flex flex-row ml-auto")}>
                {/* pagination prev btn */}

                <Pressable
                  onPress={() => {
                    if (page > 1) {
                      handleChangetwo(page - 1);
                    }
                  }}
                  style={[
                    tailwind(
                      `w-[34px] border border-colorSkyblue_100 flex justify-center items-center px-2 py-1 ml-3 rounded-lg ${
                        page === 1 ? "opacity-40" : ""
                      }`
                    ),
                    { transform: "rotate(180deg)" },
                  ]}
                >
                  <Image
                    style={[tailwind("w-[16px] h-[16px] z-[100] rounded-3xl")]}
                    contentFit="contain"
                    source={require("../assets/Arrow-right.svg")}
                  />
                </Pressable>
                {/* pagination page nums */}
                {getPagesBoxes()}

                {/* pagination next btn */}
                <Pressable
                  onPress={() => {
                    if (page < showCount) {
                      handleChangetwo(page + 1);
                    }
                  }}
                  style={tailwind(
                    `w-[34px] h-[34px] border border-colorSkyblue_100 flex justify-center items-center px-2 py-1 ml-3 rounded-lg ${
                      page === showCount ? "opacity-40" : ""
                    }`
                  )}
                >
                  <Image
                    style={[tailwind("w-[16px] h-[16px] z-[100] rounded-3xl")]}
                    contentFit="contain"
                    source={require("../assets/Arrow-right.svg")}
                  />
                </Pressable>
              </View>
            ) : null}
          </View>
        </>
      ) : (
        <Text style={tailwind("font-poppins-bold text-xl text-black")}>No Orders available</Text>
      )}
    </View>
  );
};

const getStatusclass = (orderStatus) => {
  switch (orderStatus) {
    case "InProgress":
      return { color: "yellow" };
    case "booked":
      return { color: "black" };
    case "order delivered":
      return { color: "#26C152" };
    case "order accepted":
      return { color: "#26C152" };
    case "order cancelled":
      return { color: "#E00000" };
    case "New":
      return { color: "#00B9CF" };
    case "order confirmed":
      return { color: "#00B9CF" };
    default:
      return { color: "yellow" };
  }
};

export default Table;
