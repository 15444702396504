import React from "react";
import useWebSocket from "../utils/websocket/WebSocketConnection";

const WebSocketWrapper = ({ children,  storedSound ,setStoredSound }) => {

  useWebSocket( storedSound, setStoredSound);

  return <>{children}</>;
};

export default WebSocketWrapper;
