import {
  Dimensions,
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Layout from "./Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zonelistData } from "./data/ZoneListData";
import { useRoute } from "@react-navigation/core";
import { allAllergens, getproductlist, setproductstatus } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTailwind } from "tailwind-rn";
import { Image } from "expo-image";
import { Modal, Portal } from "react-native-paper";
import AddProductForm from "./AddProductForm";
import { Defs, Svg } from "react-native-svg";
import Filter from "react-native-svg";
import FeColorMatrix from "react-native-svg";
import GrayscaleImage from "./GrayscaleImage";
import { Grayscale } from "react-native-color-matrix-image-filters";
import Toast from "react-native-toast-message";
import { setShowLoader } from "../utils/redux/slice/LoaderSlice";
// import { Grayscale} from 'react-native-color-matrix-image-filters'

const Items = () => {
  const route = useRoute();
  const tailwind = useTailwind();
  const { storeId } = route.params; // from path
  const [grouparray, setgrouparray] = useState(null);
  const [selectedTab, setSelectedTab] = useState("All");
  const [productcheck, setproductcheck] = useState(true);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [allergens, setAllergens] = useState(null);
  const [, setdisplayData] = useState();
  const [productlist, setproductlist] = useState();
  const allStores = useSelector((state) => state.store.storeListData);
  const storeData = allStores.find((store) => store.storeId === storeId);
  const [refresh, setrefresh] = useState(false);
  const dispatch= useDispatch();
  const [visible, setVisible] = useState(false);

  const showModal = () => setVisible(true);
  const hideModal = () => {
    setVisible(false);
  };

  let groupedData;

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  const showSuccessToast = (message, success) => {
    if (success) {
      showToast({ type: "success", text: message });
    } else {
      showToast({ type: "error", text: message });
    }
  };
  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        dispatch(setShowLoader(true));
        setdisplayData(zonelistData);
        // console.log('storeData',storeData)
        getproductlist(storeData?.storeId, token)
          .then((res) => {
            // console.log('Product list response',res)
            setproductlist(res.data.message);
            dispatch(setShowLoader(false));
          })
          .catch((err) => {
            console.log("Error in Product list", err);
            dispatch(setShowLoader(false));

          });

        allAllergens(token)
          .then((res) => {
            setAllergens(res.data.allergens);
          })
          .catch((err) => {
            console.log("Error in allergens", err);
          });
      }
    });
  }, [refresh, storeData?.storeId]);

  useEffect(() => {
    groupedData = productlist?.reduce((accumulator, product) => {
      if (
        !accumulator[product?.category && Object.values(product?.category)[0]]
      ) {
        accumulator[product?.category && Object.values(product?.category)[0]] =
          [];
        // Create a new category if it doesn't exist
      }
      accumulator[
        product?.category && Object.values(product?.category)[0]
      ].push(product); // Add the product to the appropriate category
      return accumulator;
    }, {});
    if (groupedData) {
      setgrouparray(Object.entries(groupedData));
    }
  }, [productlist]);

  const handleproductstatus = (item) => {
    let productstatus;
    if (item.status) {
      productstatus = "0";
    } else {
      productstatus = "1";
    }

    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        setproductstatus(item._id, productstatus)
          .then((res) => {
            setrefresh(!refresh);
            showSuccessToast(res.data.message, true);
          })
          .catch((err) => {
            console.log("Error in set product status", err);
          });
      }
    });
  };

  const columns =
    Dimensions.get("window").width <= 640
      ? 1
      : Dimensions.get("window").width <= 870
      ? 2
      : 3;

  const getItemsBoxes = () => {
    if (selectedTab === "All") {
      return productlist;
    } else {
      return grouparray?.filter((item) => item[0] === selectedTab)?.[0]?.[1];
    }
  };

  const createProductBox = (item) => {
    const screenWidth = Dimensions.get("window").width;
    const maxWidth =
      columns === 3
        ? (screenWidth - 165) / 3 - 24
        : columns === 2
        ? (screenWidth - 64) / 2 - 24
        : screenWidth - 64 - 8;

    return (
      <View
        style={[
          tailwind(
            `flex flex-col min-h-[110px] justify-between mx-3 px-2.5 py-2.5 border border-gray-300 rounded-md`
          ),
          styles.shadow,
          { maxWidth: maxWidth, minWidth: maxWidth },
        ]}
      >
        <View style={tailwind("flex flex-row w-full")}>
          {item.status !== 0 && (
            <View style={tailwind("mr-4 relative w-[82px] h-[62px]")}>
              {
                Platform.OS === "web" ? (
                  <>
                    <Svg height="0" width="0">
                      <Defs>
                        <Filter id="grayscale">
                          <FeColorMatrix
                            type="matrix"
                            values="0.33 0.33 0.33 0 0
                      0.33 0.33 0.33 0 0
                      0.33 0.33 0.33 0 0
                      0 0 0 1 0"
                          />
                        </Filter>
                      </Defs>
                    </Svg>
                    <Image
                      style={[
                        tailwind("absolute w-[82px] h-[62px]"),
                        { filter: "url(#grayscale)" },
                      ]}
                      source={item?.image}
                      alt="Store_icon"
                      contentFit="cover"
                    />
                  </>
                ) : (
                  // (<Grayscale>
                  <Image
                    style={[
                      tailwind("absolute w-[82px] h-[62px]"),
                      { filter: "url(#grayscale)" },
                    ]}
                    source={item?.image}
                    alt="Store_icon"
                    contentFit="cover"
                  />
                )
                // </Grayscale>  )
              }
              {/* <GrayscaleImage item={item}/> */}
              {/* { grayscaleImage(item) } */}
            </View>
          )}

          {item.status === 0 && (
            <View style={tailwind("mr-4")}>
              <Image
                style={[tailwind("w-[82px] h-[62px]")]}
                contentFit="cover"
                source={item?.image}
                alt="Store_icon"
              />
            </View>
          )}
          <View style={tailwind("flex flex-shrink")}>
            <Text style={tailwind("text-[13px] font-poppins-semiBold")}>
              {item?.name && Object.values(item.name)[0]}
            </Text>
            <Text style={tailwind("italic text-[10px] font-poppins-bold")}>
              {" "}
              {item?.category && Object.values(item.category)[0]}
            </Text>
            <Text
              style={tailwind(
                `${
                  item.status === 0 ? "text-colorSkyblue_100" : "text-[#a3a3a3]"
                } font-poppins-bold text-base`
              )}
            >
              {storeData?.currency?.symbol}
              {item?.price}{" "}
            </Text>
          </View>
        </View>
        <View style={tailwind("flex flex-row ml-auto items-center")}>
          <Pressable
            style={[tailwind("mr-4")]}
            onPress={(e) => {
              handleproductstatus(item);
            }}
          >
            <View
              style={[
                tailwind(
                  `w-[42px] ml-[20px] items-center flex-row p-1 rounded-2xl ${
                    item?.status === 0
                      ? "bg-colorLimegreen_100"
                      : "bg-colorTomato_100"
                  }`
                ),
              ]}
            >
              <View
                style={[
                  tailwind(
                    `w-[16px] h-[16px] rounded-full bg-white ${
                      item?.status === 0 ? "ml-auto" : "mr-auto"
                    } `
                  ),
                  styles.buttonShadow,
                ]}
              ></View>
            </View>
          </Pressable>
          <Pressable
            style={tailwind("")}
            onPress={() => {

              setModalChild(() => (
                <AddProductForm
                  hideModal={hideModal}
                  storeData={storeData}
                  setproductlist={setproductlist}
                  grouparray={grouparray}
                  allergens={allergens}
                  headerText={"Edit Product"}
                  data={item}
                />
              ));
              showModal();
            }}
          >
            <Image
              style={[tailwind("w-[20px] h-[20px]")]}
              contentFit="cover"
              source={require("../assets/edit-icon.svg")}
              alt="edit_icon"
            />
          </Pressable>
        </View>
      </View>
    );
  };

  const [modalChild, setModalChild] = useState();

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
    height: "100%",
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "80%" : "10px",
    height: Dimensions.get("screen").width < 600 ? "82%" : "80%",
    margin: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <Layout>
      <View
        style={tailwind(
          `bg-white h-[calc(100%-60px)] md:h-full p-4 rounded-[7px] w-full pb-[60px] md:pb-[0px]`
        )}
      >
        <Portal>
          <Modal
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={containerStyle}
            style={modalStyles}
          >
            {modalChild}
          </Modal>
        </Portal>
        <Pressable
          style={tailwind(
            "absolute right-0 bottom-0 rounded-full bg-colorSkyblue_100 p-3 mr-4 mb-4 z-[100]"
          )}
          onPress={() => {
            if (grouparray && allergens && storeData) {
              setModalChild(() => (
                <AddProductForm
                  hideModal={hideModal}
                  storeData={storeData}
                  setproductlist={setproductlist}
                  grouparray={grouparray}
                  allergens={allergens}
                  headerText={"Add Product"}
                />
              ));
              showModal();
            }
          }}
        >
          <Image
            style={[tailwind("w-[35px] h-[35px]")]}
            contentFit="cover"
            source={require("../assets/plus-solid.svg")}
            alt="edit_icon"
          />
        </Pressable>
        <Text style={tailwind("font-poppins-bold text-xl")}>Items</Text>
        {productlist && (
          <View
            style={tailwind("flex flex-col mt-4 h-full md:h-[calc(100%-42px)]")}
          >
            <ScrollView
              horizontal
              style={tailwind("w-full max-h-[46px]")}
              contentContainerStyle={tailwind(
                `flex flex-row h-[46px] ${
                  Platform.OS === "web" ? "w-full" : ""
                } border-b border-gray-300`
              )}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
            >
              <Pressable
                key={`grouparray-0}`}
                style={tailwind(
                  `flex-1 px-4 py-3 min-w-fit ${
                    selectedTab !== "All" ? "bg-[#E9EFF6]" : "bg-white"
                  }`
                )}
                onPress={() => setSelectedTab("All")}
              >
                <Text
                  style={tailwind(
                    "text-colorSkyblue_100 font-poppins-bold min-w-[50px] text-center"
                  )}
                >
                  All
                </Text>
              </Pressable>
              {grouparray?.map((item, index) => {
                return (
                  <Pressable
                    key={`grouparray-${index + 1}}`}
                    style={tailwind(
                      `flex-1 px-4 py-3 min-w-fit ${
                        selectedTab !== item[0] ? "bg-[#E9EFF6]" : "bg-white"
                      }`
                    )}
                    onPress={() => setSelectedTab(item[0])}
                  >
                    <Text
                      style={tailwind(
                        "text-colorSkyblue_100 font-poppins-bold min-w-[50px] text-center"
                      )}
                    >
                      {item[0]}
                    </Text>
                  </Pressable>
                );
              })}
            </ScrollView>
            <View style={tailwind("h-[90%] pb-4")}>
              <FlatList
                data={getItemsBoxes()}
                // numColumns={3}
                numColumns={columns}
                renderItem={(item) => createProductBox(item.item)}
                // style={tailwind("h-[calc(100%-50px)] mt-4 w-full")}
                style={tailwind("h-full mt-4 w-full")}
                contentContainerStyle={tailwind("")}
                ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
                keyExtractor={(item) => item?._id}
              />
            </View>
          </View>
        )}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 0 }, // Offset for shadow
    shadowOpacity: 0.12, // Opacity of the shadow
    shadowRadius: 13, // Radius of the shadow blur
  },
  buttonShadow: {
    // Android shadow
    elevation: 5,
    // iOS and web shadow
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 8,
    // Apply extra styles for web if needed
    ...(Platform.OS === "web" && {
      boxShadow: "0px 4px 8px -2px rgba(0,0,0,1)",
    }),
  },
});

export default Items;
