import axios from "axios";
import {
  setNewOrdersCount,
  setCurrentOrdersData,
  setPastOrdersData,
  setOrdersData,
} from "../utils/redux/slice/OrdersSlice";
import { REACT_APP_ORDERAT, REACT_APP_ZONE } from "@env";

export const login = (values) => {
  return axios
    .post(`${REACT_APP_ORDERAT}/admin/login`, {
      email: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
};

export const getorderlist = (size, page, token,store, dispatch) => {
  if (token) {
    return axios
      .post(
        `${REACT_APP_ORDERAT}/order/list`,
        {
          storeId: store.storeId,
          // storeId: "PUBGRUB",
          page: page,
          pageSize: 20,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let currentOrders = res?.data?.order?.filter(
          (order) => order.statusCode <= 8
        );
        let pastOrders = res?.data?.order?.filter(
          (order) => order.statusCode > 8
        );

        dispatch(setCurrentOrdersData(currentOrders));
        dispatch(setPastOrdersData(pastOrders));
        dispatch(setOrdersData(res?.data?.order));
        return res;
      });
  } else {
    return null;
  }
};

export const getstorelist = (token) => {
  return axios
    .post(
      REACT_APP_ORDERAT + `/store/list`,
      {
        zone: REACT_APP_ZONE,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setstorestatus = (
  status,
  timing,
  storeClosedMessage,
  token,
  storeId
) => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/store/setStatus`,
      {
        status: status + "",
        storeId: storeId,
        timing: timing,
        storeClosedMessage: storeClosedMessage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const cancelOrder = (
  orderId,
  otherReason,
  cancelreason,
  cancelledItems,
  token,
  storeId
) => {
  let cancellationReason = {};

  if (cancelreason === "other") {
    cancellationReason.text = otherReason;
  } else {
    cancellationReason.text = cancelreason;
  }
  if (cancelledItems.length) {
    cancellationReason.unavailableItems = cancelledItems;
  }
  return axios
    .post(
      `${REACT_APP_ORDERAT}/order/delete`,
      {
        orderId: orderId,
        storeId: storeId,
        cancellationReason: cancellationReason,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const acceptOrder = (orderId, token, storeId = "PUBGRUB") => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/order/accept`,
      {
        storeId: storeId,
        orderId: orderId,
        status: "accepted",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setproductstatus = (productId, status, token) => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/product/update/status`,
      {
        productId: productId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const allAllergens = (token) => {
  return axios
    .get(`${REACT_APP_ORDERAT}/product/allAllergens`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const getproductlist = (storeId, token) => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/product/list`,
      {
        storeId: storeId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getSiteDetails = (siteName) => {
  return axios.post(
    `${REACT_APP_ORDERAT}/site/details`,
    {
      siteName: siteName,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const askuploadtype = (filetype, token) => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/product/askuploadurl`,
      {
        type: filetype,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateimagetoCdn = (fileurl, filepath, token) => {
  return axios
    .post(fileurl, filepath, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
    })
    .then((res) => {
      return res;
    });
};

const generateRandomNumber = () => {
  const min = 10000000; // Smallest 8-digit number
  const max = 99999999; // Largest 8-digit number
  console.log(Math.floor(Math.random() * (max - min + 1)) + min, 'random')
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const addProduct = (productValues, storeId, token, selectedLanguage) => {
  let body =
    productValues.productImage !== ""
      ? {
          name: {
            [`${selectedLanguage.code}`]: productValues?.productName,
          },
          description: {
            [`${selectedLanguage.code}`]: productValues?.productDescription,
          },
          category: {
            [`${selectedLanguage.code}`]: productValues?.productCategory,
          },
          price: productValues.productPrice,
          image: productValues.productImage,
          storeId: storeId,
          productId: generateRandomNumber(),
          stock: 10,
          ingredients: productValues.ingredients,
          selectedLanguage: selectedLanguage.code,
        }
      : {
          name: {
            [selectedLanguage.code]: productValues?.productName,
          },
          description: {
            [selectedLanguage.code]: productValues?.productDescription,
          },
          category: {
            [selectedLanguage.code]: productValues?.productCategory,
          },
          price: productValues.productPrice,
          storeId: storeId,
          ingredients: productValues.ingredients,
          productId: (productValues?.productId && productValues.productId!=="")?productValues?.productId : generateRandomNumber(),
          stock: 10,
          image:
            "https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/0d87b47a-ec28-4329-85d3-14fac7903000/thumb",
          selectedLanguage: selectedLanguage.code,
        };

  return axios
    .post(`${REACT_APP_ORDERAT}/product/create`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const editProduct = (
  productId,
  productValues,
  storeId,
  token,
  selectedLanguage
) => {
  console.log(productId, productValues, 'data22')
  // console.log("productValues",productValues)
  let body =
    productValues.productImage !== ""
      ? {
          name: {
            [`${selectedLanguage.code}`]: productValues?.productName,
          },
          description: {
            [`${selectedLanguage.code}`]: productValues?.productDescription,
          },
          category: {
            [`${selectedLanguage.code}`]: productValues?.productCategory,
          },
          // en: {
          //   description: productValues.englishProductDescription,
          //   name: productValues.englishProductName,
          //   category: productValues.productCategoryEnglish
          // },
          // it: {
          //   description: productValues.spanishProductDescription,
          //   name: productValues.spanishProductName,
          //   category: productValues.productCategorySpanish
          // },
          // category: productValues.productCategoryEnglish,
          price: productValues.productPrice,
          image: productValues.productImage,
          storeId: storeId,
          ingredients: productValues.ingredients,
        }
      : {
          name: {
            [selectedLanguage.code]: productValues?.productName,
          },
          description: {
            [selectedLanguage.code]: productValues?.productDescription,
          },
          category: {
            [selectedLanguage.code]: productValues?.productCategory,
          },
          price: productValues.productPrice,
          storeId: storeId,
          ingredients: productValues.ingredients,
          image:
            "https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/0d87b47a-ec28-4329-85d3-14fac7903000/thumb",
        };

  return axios
    .put(`${REACT_APP_ORDERAT}/product/edit/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const summonRobot = (storeId, robotId, token) => {
  return axios
    .post(
      `${REACT_APP_ORDERAT}/store/summonRobot`,
      {
        storeId: storeId,
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};



export const editstore = (storeId, storeData, token) => {
  return axios
    .put(
      `${REACT_APP_ORDERAT}/store/edit`,
      {
        storeId: storeId,
        storeData: storeData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const editStore = (storeData, storeId, token, selectedLanguage) => {
  // console.log("productValues",productValues)
  console.log(storeId);
  let body = {};

  storeData =
    storeData.storeImage !== ""
      ? {
          name: {
            [`${selectedLanguage.code}`]: storeData?.storeName,
          },
          description: {
            [`${selectedLanguage.code}`]: storeData?.storeDescription,
          },
          storeManager: {
            name: storeData?.storeOwner,
          },

          // en: {
          //   description: productValues.englishProductDescription,
          //   name: productValues.englishProductName,
          //   category: productValues.productCategoryEnglish
          // },
          // it:
          //   description: productValues.spanishProductDescription,
          //   name: productValues.spanishProductName,
          //   category: productValues.productCategorySpanish
          // },
          // category: productValues.productCategoryEnglish,
          //price: productValues.productPrice,
          image: storeData.storeImage,
          storeId: storeId,
          //ingredients: productValues.ingredients,
        }
      : {
          name: {
            [`${selectedLanguage.code}`]: storeData?.storeName,
          },
          description: {
            [`${selectedLanguage.code}`]: storeData?.storeDescription,
          },
          storeManager: {
            name: storeData?.storeOwner,
          },

          storeId: storeId,

          image:
            "https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/0d87b47a-ec28-4329-85d3-14fac7903000/thumb",
        };
  body.storeId = storeId;
  body.storeData = storeData;
  return axios
    .put(`${REACT_APP_ORDERAT}/store/edit`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const editsite = (siteName, siteData, token) => {
  return axios
    .put(
      `${REACT_APP_ORDERAT}/site/edit`,
      {
        siteName: siteName,
        siteData: siteData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};