import { EscPos } from './lib';

export const thermalPrint= function(printDetails,storelistData, timeZone){
    let printCharacteristic;
    let data;
  
    let sampleData = `<?xml version="1.0" encoding="ISO-8859-1"?>
      <document>
      <align mode="center">
      <bold>
      <text-line size="1:1">Orderat ${process.env.REACT_APP_SITE_NAME}</text-line>
      </bold>
      </align>
      <align mode="left">
      <text-line size="0:0">-------------------------------</text-line>
      </align>
      <align mode="center">
      <bold>
      <text-line size="1:0">${printDetails.storeId}</text-line>
      </bold>
      </align>
      <align mode="center">
      <text-line size="0:0">${storelistData[0].location}</text-line>
      </align>
      <align mode="left">
      <text-line size="0:0">-------------------------------</text-line>
      </align>
      <align mode="center">
      <text-line size="0:0">${printDetails.userName.trim()}&nbsp;&nbsp;&nbsp;&nbsp;${"Order Date"}</text-line>
      </align>
      <align mode="center">
      <text-line size="0:0">${new Date(printDetails?.createdAt).toLocaleString("en-GB", {
        // weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZoneName: "short",
        timeZone: timeZone,
      })?.replace(","," ")} ${new Date(printDetails?.createdAt).toLocaleTimeString("en-US", {
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
        // timeZoneName: "short",
        timeZone: timeZone,
      })}</text-line>
      </align>
      <align mode="left">
      <text-line size="0:0">-------------------------------\n</text-line>
      </align>
      ${printDetails?.pickPin ? `<align mode="center">
      <bold>
      <text-line size="0:0">PIN to access Cabin</text-line>
      </bold>
      </align>
      <align mode="center">
      <bold>
      <text-line size="2:2">${printDetails?.pickPin}</text-line>
      </bold>
      </align>
      <align mode="left">
      <text-line size="0:0">-------------------------------</text-line>
      </align>` :  ``}
      <align mode="center">
      <bold>
      <text-line size="0:0">Order Details</text-line>
      </bold>
      </align>`
    printDetails?.orderData?.forEach(element => {
      sampleData += `<align mode="left">
      <text-line size="0:0">${element.name.includes("x1")
      ? element.name.split("x1")[0]
      : element.name} x${element.quantity}</text-line>
      </align>
      <align mode="right">
      <text-line size="0:0">${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${(element.price * element.quantity).toFixed(2)}</text-line>
      </align>
      `
    });
    sampleData += `
      <align mode="left">
      <text-line size="0:0">-------------------------------</text-line>
      </align>
      <align mode="right">
      <text-line size="0:0">Subtotal \t ${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${parseFloat(printDetails.cartTotal).toFixed(2)}</text-line>
      </align>
      ${printDetails?.tax && printDetails?.tax > 0 ? (
        `<align mode="right">
        <text-line size="0:0">Sales Tax \t ${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${printDetails.tax}</text-line>
        </align>`
      ): ``}
      ${storelistData[0]?.showDeliveryFee && printDetails?.deliveryfee && printDetails?.deliveryfee > 0 ? `<align mode="right">
      <text-line size="0:0">Delivery Fee ${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${(printDetails?.deliveryfee)?.toFixed(2)}</text-line>
      </align>`:  `` }
      ${printDetails?.discountCode ? `<align mode="right">
      <text-line size="0:0">Discount Code \t ${printDetails.discountCode}</text-line>
      </align>`: ``}
      ${printDetails?.discount ? `<align mode="right">
      <text-line size="0:0">Discount \t ${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${parseFloat(printDetails?.discount).toFixed(2)}</text-line>
      </align>`:``}
      <align mode="left">
      <text-line size="0:0">-------------------------------</text-line>
      </align>
      <align mode="right">
      <bold>
      <text-line size="0:0">Total \t ${printDetails.currency === "€" ? "EUR ": printDetails.currency === "₹" ? "Rs. " : printDetails.currency === "£" ? "GBP " : printDetails.currency}${printDetails?.discount ? (parseFloat(printDetails.cartTotal)+parseFloat(printDetails.tax)-parseFloat(printDetails?.discount)).toFixed(2): (parseFloat(printDetails.cartTotal)+parseFloat(printDetails.tax)).toFixed(2)}</text-line>
      </bold>
      </align>
      <line-feed/>
      <align mode="center">
      <text-line size="0:0">This is an online-generated \nreceipt and does not \nrequire a signature</text-line>
      </align>
      <line-feed/>
      <align mode="center">
      <text-line size="0:0">${process.env.REACT_APP_SITE_LINK}</text-line>
      </align>
      <line-feed/>
      <align mode="center">
      <bold>
      <text-line size="0:0">Powered By</text-line>
      </bold>
      </align>
      <align mode="center">
      <bold>
      <text-line size="0:0">Ottonomy.io</text-line>
      </bold>
      </align>
      </document>`

      // console.log(sampleData, "sampleData")
    // console.log(props?.data)
    // console.log(new Date(props?.data.createdAt).toDateString())
  
    // let image = document.querySelector("#image")
    let canvas = document.createElement('canvas');
  
    // Canvas dimensions need to be a multiple of 40 for this printer
    canvas.width = 400;
    canvas.height = 90;
    let context = canvas.getContext("2d");
    // context.drawImage(image, 20, 0, 400, 70);
    let imageData = context.getImageData(20, 0, canvas.width, canvas.height).data;
    // document.getElementById("root").append(canvas)
  
    function getDarkPixel(x, y) {
      // Return the pixels that will be printed black
      let red = imageData[((canvas.width * y) + x) * 4];
      let green = imageData[((canvas.width * y) + x) * 4 + 1];
      let blue = imageData[((canvas.width * y) + x) * 4 + 2];
      return (red + green + blue) > 0 ? 1 : 0;
    }
  
    function getImagePrintData() {
      if (imageData == null) {
        // console.log('No image to print!');
        return new Uint8Array([]);
      }
      // Each 8 pixels in a row is represented by a byte
      let printData = new Uint8Array(canvas.width / 8 * canvas.height + 8);
      let offset = 0;
      // Set the header bytes for printing the image
      printData[0] = 29;  // Print raster bitmap
      printData[1] = 118; // Print raster bitmap
      printData[2] = 48; // Print raster bitmap
      printData[3] = 0;  // Normal 203.2 DPI
      printData[4] = canvas.width / 8; // Number of horizontal data bits (LSB)
      printData[5] = 0; // Number of horizontal data bits (MSB)
      printData[6] = canvas.height % 256; // Number of vertical data bits (LSB)
      printData[7] = canvas.height / 256;  // Number of vertical data bits (MSB)
      offset = 7;
      // Loop through image rows in bytes
      for (let i = 0; i < canvas.height; ++i) {
        for (let k = 0; k < canvas.width / 8; ++k) {
          let k8 = k * 8;
          //  Pixel to bit position mapping
          printData[++offset] = getDarkPixel(k8 + 0, i) * 128 + getDarkPixel(k8 + 1, i) * 64 +
            getDarkPixel(k8 + 2, i) * 32 + getDarkPixel(k8 + 3, i) * 16 +
            getDarkPixel(k8 + 4, i) * 8 + getDarkPixel(k8 + 5, i) * 4 +
            getDarkPixel(k8 + 6, i) * 2 + getDarkPixel(k8 + 7, i);
        }
      }
      return printData;
    }
  
    function sendImageData() {
      data = getImagePrintData();
      // console.log(data)
  
    }
  
    const generateBuffer = (template, data) => {
      // Will add implementation here to generate buffer
      return EscPos.getBufferFromXML(template, data);
    };
  
    function sendTextData(message) {
      var maxChunk = 200;
      var j = 0;
      // console.log(message)
      let mergedArray = new Uint8Array(message.length + data.length + 1);
      mergedArray.set(message);
      mergedArray.set(data, message.length);
      if (mergedArray.length > maxChunk) {
        for (var i = 0; i < mergedArray.length; i += maxChunk) {
          var subStr;
          if (i + maxChunk <= mergedArray.length) {
            subStr = mergedArray.slice(i, i + maxChunk);
  
          } else {
            subStr = mergedArray.slice(i, mergedArray.length);
          }
  
          setTimeout(writeStrToCharacteristic, 250 * j, subStr);
          j++;
        }
      } else {
        writeStrToCharacteristic(mergedArray);
      }
    }
    function writeStrToCharacteristic(str) {
      let buffer = new ArrayBuffer(str.length);
      let dataView = new DataView(buffer);
      for (var i = 0; i < str.length; i++) {
        dataView.setUint8(i, str[i]);
      }
      return printCharacteristic.writeValue(buffer);
    }
  
    const template = sampleData;
    const message = generateBuffer(template);
  
    const printReceipt = async () => {
      navigator.bluetooth.requestDevice({ filters: [{ services: ["000018f0-0000-1000-8000-00805f9b34fb"] }] })
        .then(device => {
          return device.gatt.connect();
        })
        .then(server => server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb"))
        .then(service => service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb"))
        .then(characteristic => {
          printCharacteristic = characteristic;
          sendImageData()
          sendTextData(message);
        })
        .catch(error => {
          console.log('Argh! ' + error);
        });
    }
    printReceipt()
}