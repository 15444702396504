import { createSlice } from "@reduxjs/toolkit"

const LoaderSlice = createSlice({
  name: "loaderSlice",
  initialState: {
    showLoader: false,
  },
  reducers: {
    setShowLoader: (state, action) => {
      state.showLoader = action.payload
    },
  }
})

export const {setShowLoader} = LoaderSlice.actions;
export default LoaderSlice.reducer;