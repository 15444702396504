import React, { useEffect, useState } from "react";
import { Dimensions, Platform, Pressable, Text, View } from "react-native";
import { Modal, Portal } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { setModalVisible } from "../utils/redux/slice/ModalSlice";
import { Audio } from "expo-av";
import { stopSound } from "../utils/websocket/WebSocketConnection";
import ConfirmationPopup from "./ConfirmationPopup";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { useTailwind } from "tailwind-rn";
import { Image } from "expo-image";

const GlobalModal = ({ storedSound, setStoredSound, path }) => {
  const modalVisible = useSelector((state) => {
    return state.modal.modalVisible;
  });
  const newOrdersCount = useSelector((state) => state?.orders?.newOrdersCount);

  const orders = useSelector((state) => state?.orders?.ordersData);
  const newOrders = orders.filter((order) => order?.statusCode <= 2);
  const storeListData = useSelector((state) => state?.store?.storeListData);

  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const tailwind = useTailwind();
  // const storedSound= useSelector((state) => state.modal.storedSound);

  const showModal = () => dispatch(setModalVisible(true));
  const hideModal = () => {
    dispatch(setModalVisible(false));
    stopSound(storedSound);
  };

  useEffect(() => {
    if (!modalVisible) {
      stopSound(storedSound);
    }
  }, [modalVisible]);

  useEffect(() => {
    return storedSound
      ? () => {
          console.log("Unloading Sound");
        }
      : undefined;
  }, [storedSound]);

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    // width: Platform.OS === "web" ? "500px" : "10px",
    width: Math.min(Dimensions.get('window').width-20, 500),
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <Portal>
      {newOrdersCount > 0 && (
        <Modal
          visible={modalVisible}
          onDismiss={() => {
            hideModal();
            stopSound(storedSound);
          }}
          contentContainerStyle={containerStyle}
          style={modalStyles}
        >
          {newOrdersCount > 1 ? (
            <ConfirmationPopup
              title={`${newOrdersCount} New Orders`}
              question="Go to Orders Page now"
              onSubmit={() => {
                linkTo("/orderDashboard");
                stopSound(storedSound);
              }}
              hideModal={() => {
                hideModal();
                stopSound(storedSound);
              }}
            />
          ) : (
            <View>
              <View
                style={tailwind(
                  "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
                )}
              >
                <Text
                  style={tailwind(
                    "text-2xl text-white font-bold font-poppins-bold"
                  )}
                >
                  You have 1 new Order
                </Text>
                <Pressable onPress={() => hideModal()}>
                  <Image
                    style={tailwind("w-[24px] h-[24px]")}
                    contentFit="cover"
                    source={require("../assets/-icon-close.png")}
                  />
                </Pressable>
              </View>
              <View style={tailwind("pb-5")}>
                <View
                  style={tailwind(
                    "flex items-center justify-between w-full min-h-[108px]"
                  )}
                >
                  <View
                    style={[
                      tailwind(
                        "justify-between flex flex-row py-3 w-full px-2 sm:px-4 items-center max-h-[102px]"
                      ),
                      // { backgroundColor: "#e5f7fa" },
                    ]}
                  >
                    <View
                      style={[
                        tailwind(
                          "h-full flex-grow mr-2 sm:mr-0 text-left text-colorDarkslategray_100 flex flex-col sm:flex-row shrink sm:shrink-0"
                        ),
                      ]}
                    >
                      <View
                        style={tailwind(
                          "flex flex-col md:flex-row items-baseline md:items-center"
                        )}
                      >
                        <View style={tailwind("flex flex-row items-baseline")}>
                          <Text
                            style={[
                              tailwind(
                                "text-lg font-bold font-poppins-bold text-colorDarkslategray_100"
                              ),
                            ]}
                          >
                            #{newOrders[0]?.orderId}
                          </Text>

                          <Text
                            style={[
                              tailwind(
                                "text-[8px] font-bold font-poppins-bold text-colorDarkslategray_100"
                              ),
                            ]}
                          >
                            {" "}
                            ,{" "}
                          </Text>
                        </View>
                        <Text
                          style={[
                            tailwind(
                              "text-xs font-poppins-bold text-colorDarkslategray_100"
                            ),
                          ]}
                        >
                          {newOrders[0]?.storeId}
                        </Text>
                      </View>
                      <Text
                        style={[
                          tailwind(
                            "font-semibold text-left flex items-center text-xs text-colorDarkslategray_100 mt-2 sm:mt-0 sm:mx-auto min-w-[120px] font-poppins-semiBold"
                          ),
                        ]}
                      >
                        {new Date(newOrders[0]?.createdAt)
                          .toLocaleString("en-GB", {
                            // weekday: "short",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            timeZoneName: "short",
                            timeZone: storeListData?.[0]?.timezone,
                          })
                          ?.replace(",", " ")}{" "}
                        ,{" "}
                        {new Date(newOrders[0]?.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            timeZone: storeListData?.[0]?.timezone,
                          }
                        )}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={[
                      tailwind(
                        "max-h-[85px] items-center rounded-b-lg pl-3 sm:pl-5 py-2 pr-4 sm:pr-8 justify-between w-full flex-row items-start"
                      ),
                      // { backgroundColor: "#e5f7fa" },
                    ]}
                  >
                    <View
                      style={tailwind("flex flex-col shrink min-w-[42px] mr-1")}
                    >
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Name
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {newOrders[0]?.userName}
                      </Text>
                    </View>
                    <View style={tailwind("flex flex-col shrink")}>
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Contact Number
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {newOrders[0]?.mobileNumber}
                      </Text>
                    </View>
                    <View style={tailwind("flex flex-col shrink")}>
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Delivery Location
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {newOrders[0]?.drop?.locationName?.["en-US"]}
                      </Text>
                    </View>
                  </View>

                  <Pressable
                    style={tailwind(
                      "bg-colorLimegreen_100 py-2 px-8 rounded-lg mt-2"
                    )}
                    onPress={() => {
                      linkTo("/orderDashboard");
                      hideModal();
                    }}
                  >
                    <Text style={tailwind("font-poppins-bold text-white")}>
                      Go to Orders
                    </Text>
                  </Pressable>
                </View>
              </View>
            </View>
          )}
        </Modal>
      )}
    </Portal>
  );
};

export default GlobalModal;
