import * as React from "react";
import { TailwindProvider, useTailwind } from "tailwind-rn";
import utilities from "./tailwind.json";
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import { Provider } from "react-redux";
import { PaperProvider } from "react-native-paper";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Platform, StyleSheet, Text } from "react-native";
import * as Font from "expo-font";
import { MaterialCommunityIcons } from "@expo/vector-icons"; // For React Native Paper icons
import AsyncStorage from "@react-native-async-storage/async-storage";
import { store } from "./utils/redux/store";
import { SafeAreaProvider } from "react-native-safe-area-context";

// Screens
import { Login } from "./screens/Login";
import OrderDashboard from "./screens/OrderDashboard";
import Stores from "./screens/Stores";
import Reports from "./screens/Reports";
import Ottobots from "./screens/Ottobots";
import Items from "./screens/Items";
import GlobalModal from "./screens/GlobalModal";
import WebSocketWrapper from "./screens/WebSocketWrapper";

const Stack = createNativeStackNavigator();

const App = () => {
  const [hideSplashScreen, setHideSplashScreen] = React.useState(true);
  const [initialRouteName, setInitialRouteName] =
    React.useState("OrderDashboard");
  const [storedSound, setStoredSount] = React.useState(null);
  const tailwind = useTailwind();

  // Font loading for web and mobile, including MaterialCommunityIcons
  const [fontsLoaded, setFontsLoaded] = React.useState(false);

  React.useEffect(() => {
    async function loadFonts() {
      await Font.loadAsync({
        poppinsRegular: require("./assets/fonts/Poppins-Regular.ttf"),
        poppinsMedium: require("./assets/fonts/Poppins-Medium.ttf"),
        poppinsSemiBold: require("./assets/fonts/Poppins-SemiBold.ttf"),
        poppinsBold: require("./assets/fonts/Poppins-Bold.ttf"),
        russoOneRegular: require("./assets/fonts/RussoOne-Regular.ttf"),
        MaterialCommunityIcons: require("./assets/fonts/MaterialCommunityIcons.ttf"), // Load Material Icons
      });
      setFontsLoaded(true);
    }
    loadFonts();
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  AsyncStorage.getItem("token").then((token) => {
    if (!token === null || token === "null" || token === undefined || !token) {
      setInitialRouteName("Layout");
    }
  });

  const config = {
    screens: {
      Login: "login",
      OrderDashboard: "orderDashboard",
      Stores: "stores",
      Reports: "reports",
      Ottobots: "ottobots",
      Layout: "dashboard",
      Items: "items/:storeId",
    },
  };

  const linking = {
    prefixes: [""],
    config,
  };

  const toastConfig = {
    success: (props) => (
      <BaseToast
        {...props}
        style={{ borderLeftColor: 'green', borderLeftWidth: 10 }}
        contentContainerStyle={{ paddingHorizontal: 15 }}
        text1Style={{fontSize: 16, fontWeight: 'bold', fontFamily: "poppinsSemiBold"}}
        text2Style={{fontSize: 16, fontWeight: 'bold', fontFamily: "poppinsSemiBold"}}
      />
    ),
    error: (props) => (
      <ErrorToast
        {...props}
        style={{ borderLeftColor: 'red', borderLeftWidth: 10 }}
        contentContainerStyle={{ paddingHorizontal: 15 }}
        text1Style={{fontSize: 16, fontWeight: 'bold', fontFamily: "poppinsSemiBold"}}
        text2Style={{fontSize: 16, fontWeight: 'bold', fontFamily: "poppinsSemiBold"}}
      />
    ),
  };

  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <TailwindProvider utilities={utilities}>
          <PaperProvider
            settings={{
              icon: (props) => <MaterialCommunityIcons {...props} />,
            }}
          >
            <WebSocketWrapper
              storedSound={storedSound}
              setStoredSound={setStoredSount}
            >
              <NavigationContainer
                linking={linking}
                fallback={<Text>Loading...</Text>}
              >
                <Stack.Navigator initialRouteName={initialRouteName}>
                  <Stack.Screen
                    name="Login"
                    component={Login}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="OrderDashboard"
                    component={OrderDashboard}
                    options={{ headerShown: false, title: "" }}
                  />
                  <Stack.Screen
                    name="Stores"
                    component={Stores}
                    options={{ headerShown: false, title: "" }}
                  />
                  <Stack.Screen
                    name="Reports"
                    component={Reports}
                    options={{ headerShown: false, title: "" }}
                  />
                  <Stack.Screen
                    name="Ottobots"
                    component={Ottobots}
                    options={{ headerShown: false, title: "" }}
                  />
                  <Stack.Screen
                    name="Items"
                    component={Items}
                    options={{ headerShown: false, title: "" }}
                  />
                </Stack.Navigator>

                <GlobalModal
                  storedSound={storedSound}
                  setStoredSound={setStoredSount}
                />
              </NavigationContainer>
            </WebSocketWrapper>
          </PaperProvider>
          <Toast config={toastConfig} />
          {/* <Toast config={toastConfig} /> */}
        </TailwindProvider>
      </SafeAreaProvider>
    </Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text1Style: {
    fontSize: 16, // Increase font size for title
    fontWeight: 'bold',
    fontFamily: "poppins-bold"
  },
  text2Style: {
    fontSize: 18, // Increase font size for message
    color: 'gray',
  },
});

export default App;
